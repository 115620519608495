import "@hotwired/turbo-rails"
import "./controllers"
import "trix"
import "@rails/actiontext"
import "lodash"
import "swiper/bundle"
import 'swiper/css/bundle';
import "mapkick/bundle"
import "flowbite/dist/flowbite.turbo.js";
import "tom-select/dist/css/tom-select.css";
import "lazysizes"
import 'lazysizes/plugins/blur-up/ls.blur-up';
import "video.js/dist/video-js.css";
import "video.js/dist/video.min.js";
import "chartkick/chart.js"

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import confetti from "canvas-confetti";

document.addEventListener("turbo:load", () => {

  const handleConfetti = document.querySelectorAll('.js-confetti')
  const confettiDelay = 350;// tweak this delay and confetti's params to create the illusion of confetti popping from behind the modal and falling in front of it

  handleConfetti.forEach((el) => {
    const confettiConvas = document.createElement('canvas')
    el.appendChild(confettiConvas)

    confettiConvas.width = el.offsetWidth
    confettiConvas.height = el.offsetHeight
    confettiConvas.className = "z-20 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2";

    var drawConfetti = confetti.create(confettiConvas, {
      resize: true,
      useWorker: true
    })

    function fireConfetti() {
      var count = 200;
      var defaults = {
        origin: { y: 0.45 },
        gravity: 0.7,
        zIndex: 20
      };

      function fire(particleRatio, opts) {
        drawConfetti(
          Object.assign({}, defaults, opts, {
            particleCount: Math.floor(count * particleRatio),
            // all from greeninvoice's pie chart colors
            colors: [
              "#FF5100"
            ]
          })
        );
      }

      fire(0.25, {
        spread: 26,
        startVelocity: 55
      });
      fire(0.2, {
        spread: 60
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45
      });
    }

    setTimeout(fireConfetti, confettiDelay)
  })

})